<script lang="ts" setup>
import type { HomeGamesList, GamesFilters } from "@/types";

withDefaults(
	defineProps<{
		games: HomeGamesList[] | HomeGamesList | GamesFilters;
		title: string;
		showAll?: boolean;
		hideFavoriteBtn?: boolean;
		icon?: string;
		isAdminIcon?: boolean;
	}>(),
	{
		showAll: false
	}
);

defineEmits<{ (e: "viewAllAction"): void }>();

const { t } = useT();
const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark", toastPosition: "bottom-center" });
</script>

<template>
	<div>
		<MSliderWrapper
			:list="games as []"
			:title="title"
			:showAll="showAll"
			:icon="icon || ''"
			:isAdminIcon="isAdminIcon"
			:spacing="0"
			@view-all-action="$emit('viewAllAction')"
		>
			<template #default="{ item }">
				<div class="keen-slider__slide">
					<div class="wrapper">
						<MGame
							v-for="(game, index) in Array.isArray(item) ? item : [item]"
							:key="index"
							:game="game"
							:hideFavoriteBtn="hideFavoriteBtn"
							hideTitle
							@toggle-favorite="handleToggleToFavoriteClick"
						/>
					</div>
				</div>
			</template>
		</MSliderWrapper>
	</div>
</template>

<style lang="scss" scoped>
.wrapper {
	width: 174px;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	gap: 16px;
	padding: 0 8px;

	@include media-breakpoint-down(lg) {
		width: 132px;
		gap: 12px;
		padding: 0 6px;
	}
}
</style>
<style lang="scss">
.Toastify__toast-container--bottom-center {
	@include media-breakpoint-up(lg) {
		left: auto;
		right: 16px;
		transform: translateX(0%);
	}
}
</style>
